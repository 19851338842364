import React, { useEffect } from "react";
import "../Style/aboutUs.scss";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  // -----------------------

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  // ---------------------

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="craq solutions about" />
        <meta
          name="keywords"
          content="craq solutions, craq, digital marketing, craqsolutions.com, about us, craq digital solutions"
        />
      </Helmet>
      <div className="aboutUsPageOutterBody">
        <div className="aboutUsPageInnerBody">
          <div className="aboutUsPageBannerAndText">
            <div className="aboutUsPageBannerText">
              <span>
                <h1>Welcome to the heart and soul of Craq,</h1>
                <p>
                  Let's embark on this exciting journey together and bring your
                  digital dreams to life
                </p>
              </span>
            </div>
          </div>

          <div className="aboutUsMainDiv">
            <div className="aboutUsAboutAndMissionDiv">
              <div className="aboutDiv">
                <h2>About</h2>
                <p>
                  Our journey begins with a simple yet profound idea: that the
                  world of digital solutions should be accessible to every
                  business, regardless of its size or industry. Here in Craq, We
                  are focussed and determined to empower businesses and
                  individuals with cutting-edge digital marketing strategies,
                  transformative website designs, and innovative application
                  development.
                </p>
              </div>
              <div className="ourMissionDiv">
                <h2>Our Mission</h2>
                <p>
                  Our mission is clear and heartfelt: We're here to help you
                  turn your digital dreams into reality.We are here to lift your
                  business with our proven omni directional Digital strategies
                  with Technical Expertise. We firmly believe that your success
                  in the digital realm is a testament to our own success.Your
                  goals are our goals.
                </p>
              </div>
            </div>
          </div>
          <div className="ourApproachMainBodyDiv">
            <div className="ourApproachTextItemDiv">
              <h2>Our Approach</h2>
              <p>
                What truly sets us apart is our unwavering devotion to
                excellence and commitment of delivery. We don't believe in
                settling for mediocrity; we strive for nothing short of
                extraordinary. Our approach is grounded in three fundamental
                principles:
              </p>
            </div>
            <div className="ourApproachCardBody">
              <div class="ourApproachCardContainer">
                <div class="card">
                  <div class="front">
                    <h3>Expertise</h3>
                  </div>
                  <div class="back">
                    <p>
                      Our team consists of dedicated individuals who are not
                      just experts in their fields but also deeply passionate
                      about what they do. we are real people driven by the
                      desire to deliver exceptional results.
                    </p>
                  </div>
                </div>
              </div>
              <div class="ourApproachCardContainer">
                <div class="card">
                  <div class="front">
                    <h3>Innovation</h3>
                  </div>
                  <div class="back">
                    <p>
                      Change excites us, and we eagerly embrace the latest in
                      digital technology. We see innovation as an opportunity to
                      make a meaningful difference for our clients.
                    </p>
                  </div>
                </div>
              </div>
              <div class="ourApproachCardContainer">
                <div class="card">
                  <div class="front">
                    <h3>Results</h3>
                  </div>
                  <div class="back">
                    <p>
                      Your satisfaction is our ultimate barometer of success. We
                      measure our achievements by how effectively we help you
                      reach your business objectives.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="ourTeamMainBodyDiv">
            <div className="ourTeamCard">
              <h3>Our Team</h3>
              <p>
                Behind the delivery of every successful project, you'll find an
                exceptionally talented team, and we take immense pride in ours.
                Our team members come from splendid technical backgrounds, each
                contributing their unique skills, creativity, and innovative
                thinking to your projects.
              </p>
            </div>
            <div className="ourClientCentricCard">
              <h3>Client-Centric Approach</h3>
              <p>
                At the core of everything we do is a commitment to you. By
                immersing ourselves in your business, its challenges, and its
                aspirations, we craft tailor-made solutions that seamlessly
                align with your vision.
              </p>
            </div>
            <div className="ourCommitmentCard">
              <h3>Our Commitment to Quality</h3>
              <p>
                Quality isn't just a buzzword for us; it's the very essence of
                our work. We adhere to stringent quality assurance processes to
                ensure that every project we undertake meets the highest
                standards. Excellence is our unwavering standard.
              </p>
            </div>
          </div>
          <div className="joinUsDiv">
            <h3>Join Us on Your Digital Journey</h3>
            <p>
              Whether your goal is to conquer the online marketplace, establish
              an unforgettable online presence, or create revolutionary
              applications, we're here to be your trusted partners every step of
              the way. At Craq, we're not just a service provider; we're your
              allies in your digital pursuits.
            </p>
            <p>
              Let's embark on this exciting journey together and bring your
              digital dreams to life.
            </p>
            <Link to="/Contact-Us">
              <button>Contact us today to begin</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
