import React from "react";
import "../Style/Style_CompanyAboutLandingPage.scss";
import aboutImg from "../Img/about.jpg";
import { Link } from "react-router-dom";
const Company = () => {
  return (
    <>
      <div className="companyOutterBody">
        <div className="companyInnerBody">
          {/* <h1>Welcome to Craq Digital Solutions</h1> */}
          <h1>Cherish the tailor-made solutions with Craq</h1>
          <div className="companyTextMainBody">
            <img src={aboutImg} id="companyImg" />
            <span>
              <p id="companyTopSection">
                In today's fast-paced digital era, Indulging yourself in the
                digital world is the only choice – it's a must to have for your
                business growth. Almost every Business, big or small, is
                navigating towards the ever-evolving arena of digital marketing,
                website designing, and app development and this is where{" "}
                <b> Craq Digital Solutions </b>comes into the picture – yours
                the most trusted partner on the journey to digital excellence.
              </p>
              <p>
                We are here to help you grow like a brand and turn your dreams
                into reality, We respect your efforts and Ideas for your
                business and we are here to uplift your business with our proven
                omnidirectional Digital strategies with Technical Expertise. We
                are fully confident and firmly believe that your satisfaction
                with our strategies is the only attestation to our success. At
                the crux of everything we do is our dedication and commitment
                towards favourable results for you. By involving ourselves in
                your business, its complexities, its challenges, and its vision,
                we craft tailor-made digital solutions that seamlessly align
                with your ambitions and make you stand out in the Internet
                world.
              </p>
              <p>
                Whether your focus is to hit the online marketplace, establish
                your never-ending online presence, or create widely used
                applications, we're here to be your trusted companions every
                step of the way. At Craq, We believe Client satisfaction is the
                only key to our growth and it motivates us for more quality
                work.
              </p>
            </span>
          </div>
          <div className="CompanySectionBtn">
            <Link to="/Contact-Us">
              <button>Contact us today to begin</button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Company;
