import React, { useState } from "react";
import "../Style/Style_FontPageLandingPage.scss";
import landingPageImg from "../Img/landing_page_img.avif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
const LandingPage = () => {
  return (
    <>
      <div className="landingPageOutterBody">
        <div className="landingPageInnerBody">
          <div className="landingPageTextBody">
            <h1>
              Let’s Crack Digital Solutions With <strong>Craq</strong>
            </h1>
            <p>
              Uplift your business with our proven omni channel digital services
              that delivers results
            </p>
            <Link to="/Contact-Us">
              <button>
                Connect With Us
                <FontAwesomeIcon id="arrowstyle" icon={faArrowRight} />
              </button>
            </Link>
          </div>
          <div className="landingPageImgBody">
            <img src={landingPageImg} />
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
