import React from "react";
import "../Style/organicReach.scss";
import seo from "../Img/organic.avif";
// -------
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearchengin } from "@fortawesome/free-brands-svg-icons";
import {
  faBullhorn,
  faPenToSquare,
  faMapLocationDot,
  faChartLine,
  faEnvelopeCircleCheck,
} from "@fortawesome/free-solid-svg-icons";
// --

function OrganicReach() {
  return (
    <>
      <div className="organicReachPageOutterBody">
        <div className="organicReachPageInnerBody">
          <div className="organicReachPageTitleAndImg">
            <div className="organicReachPageTitle">
              <h1>Organic Reach</h1>
              <p>
                We work on real and natural content by which the audience can
                attract and relate to their requirements. We help websites to
                increase their reach organically and come to the top of search
                engines. Our Analyst does the deep-down analysis, brings the
                website analysis reports, and suggests the changes according to
                the market trend.
              </p>
            </div>
            <img src={seo} />
          </div>
          <div className="organicReachPageItem">
            <ul>
              <li>
                <div className="organicReachPageItemText">
                  <FontAwesomeIcon
                    icon={faSearchengin}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Search Engine Optimization</h1>
                  <p>
                    Craq Team analyzes the website content, designs, and
                    performance and helps websites to trend on the search engine
                    organically.
                  </p>
                </div>
              </li>
              <li>
                <div className="organicReachPageItemText">
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Content Writing</h1>
                  <p>
                    Our Content writers are market experts who organically bring
                    your website to the top.
                  </p>
                </div>
              </li>
              <li>
                <div className="organicReachPageItemText">
                  <FontAwesomeIcon
                    icon={faMapLocationDot}
                    style={{ fontSize: "40px" }}
                  />

                  <h1>Local Search Optimization</h1>
                  <p>
                    We help businesses to grow locally through local SEO,
                    location pages & and Google My Business according to locale
                    and region.
                  </p>
                </div>
              </li>
              <li>
                <div className="organicReachPageItemText">
                  <FontAwesomeIcon
                    icon={faChartLine}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Website Analysis</h1>
                  <p>
                    Our Experts do a deep-down analysis of websites and share
                    the reports with Clients.
                  </p>
                </div>
              </li>
              <li>
                <div className="organicReachPageItemText">
                  <FontAwesomeIcon
                    icon={faBullhorn}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Campaign Audits</h1>
                  <p>
                    We provide a service where we analyze existing Campaigns and
                    help to ensure they bring results.
                  </p>
                </div>
              </li>
              <li>
                <div className="organicReachPageItemText">
                  <FontAwesomeIcon
                    icon={faEnvelopeCircleCheck}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Email Marketing</h1>
                  <p>
                    We help excel businesses by email marketing to reach
                    potential and Target audiences.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrganicReach;
