import React from "react";
import socialMediaImg from "../Img/socialMedia.jpg";
import "../Style/socialMediaMarketing.scss";
// ----------------
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedinIn,
  faFacebookF,
  faInstagram,
  faTwitter,
  faSquareSnapchat,
  faYoutube,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
// ----------------

const SocialMediaMarketing = () => {
  return (
    <>
      <div className="socialMediaPageOutterBody">
        <div className="socialMediaPageInnerBody">
          <div className="socialMediaPageTitleAndImg">
            <img src={socialMediaImg} />
            <div className="socialMediaPageTitle">
              <h1>Social Media Marketing</h1>
              <p>
                In today’s world, social media is becoming a part of everyone’s
                daily routine. If your brand is not on social media then you are
                missing your chance to reach your potential intended audience.
                In Craq, the Social media team is a fusion of young brains and
                Experience players. You will love the way our team builds your
                presence like a premium brand on social media platforms i.e.,
                Linked In, Instagram, Meta (Facebook), X (Twitter), YouTube,
                Snapchat, Tiktok. Let’s start your social media brand awareness
                journey with us.
              </p>
            </div>
          </div>
          <div className="socialMediaPageItem">
            <ul>
              <li>
                <div className="socialMediaPageItemText">
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Linked In</h1>
                  <p>
                    LinkedIn is a professional platform and we professionally
                    run your campaigns to get quality and genuine leads for your
                    business.
                  </p>
                </div>
              </li>
              <li>
                <div className="socialMediaPageItemText">
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Instagram</h1>
                  <p>
                    Nowadays, Instagram is the biggest social media platform
                    where you can reach all types of audiences. We intelligently
                    target this audience for our clients.
                  </p>
                </div>
              </li>
              <li>
                <div className="socialMediaPageItemText">
                  <FontAwesomeIcon
                    icon={faFacebookF}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Meta (Facebook)</h1>
                  <p>
                    Our experts promote your business on the world’s widely used
                    platform i.e., Facebook ads, with their best approach and
                    bring more leads through appropriate campaigns.
                  </p>
                </div>
              </li>
              <li>
                <div className="socialMediaPageItemText">
                  <FontAwesomeIcon
                    icon={faYoutube}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>YouTube</h1>
                  <p>
                    Attract your audience through engaging visual content and
                    leave a deep mark on your brand through YouTube video ads.
                  </p>
                </div>
              </li>
              <li>
                <div className="socialMediaPageItemText">
                  <FontAwesomeIcon
                    icon={faXTwitter}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>X (Twitter)</h1>
                  <p>
                    Our Team puts efforts into searching customers on Twitter
                    for our partner companies and runs campaigns for them.
                  </p>
                </div>
              </li>

              <li>
                <div className="socialMediaPageItemText">
                  <FontAwesomeIcon
                    icon={faSquareSnapchat}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Snapchat</h1>
                  <p>
                    Snapchat is globally used and has become a great endorsement
                    platform. Our Team works closely with the clients and helps
                    them to reach the target audience.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMediaMarketing;
