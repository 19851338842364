import React, { useEffect, useRef, useState } from "react";
import "../Style/servicePage.scss";
import { Link } from "react-router-dom";
import CreativeDesignPage from "./CreativeDesign";
import PaidAdvertising from "./PaidAdvertising";
import SocialMediaMarketing from "./SocialMediaMarketing";
import OrganicReach from "./OrganicReach";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const ServicePage = () => {
  const [activeButton, setActiveButton] = useState(null);

  const [isNavbarFixed, setIsNavbarFixed] = useState(false);
  const innerNavbar = useRef();

  // -----------------------

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  // ---------------------

  useEffect(() => {
    const handleScroll = () => {
      setIsNavbarFixed(window.scrollY > 350);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // --------------
  const creativeDesign = useRef();
  const paidAdvertising = useRef();
  const socialMediaMarketing = useRef();
  const organicReach = useRef();

  const scrollHandler = (event, elmRef, linkId) => {
    setActiveButton(linkId);
    event.preventDefault();
    window.scrollTo({
      top: elmRef.current.offsetTop - 100,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Helmet>
        <title>Services</title>
        <meta name="description" content="digital marketing services" />
        <meta
          name="keywords"
          content="craq solutions, craq, digital marketing, craqsolutions.com, services page, craq digital solutions"
        />
      </Helmet>
      <div className="servicePageOutterBody">
        <div className="servicePageInnerBody">
          <div className="InnerBannerBody">
            <div className="servicePageBannerText">
              <span>
                <h1>Digital Marketing Services</h1>
                <p>Invest in Your Future with an agency that Gets Results</p>
              </span>
            </div>
          </div>
          <nav
            className={
              isNavbarFixed ? "serviceNavbar serviceFixed" : "serviceNavbar"
            }
          >
            <div className="servicePageLocalNavbar" ref={innerNavbar}>
              <ul>
                <li>
                  <Link
                    to="#Creative-Design"
                    onClick={(event) =>
                      scrollHandler(event, creativeDesign, "home")
                    }
                    className={activeButton === "home" ? "active" : ""}
                  >
                    Creative Services
                  </Link>
                </li>
                <li>
                  <Link
                    to="#Paid-Advertising"
                    onClick={(event) => scrollHandler(event, paidAdvertising)}
                  >
                    Paid Advertising
                  </Link>
                </li>
                <li>
                  <Link
                    to="#Social-Media-Marketing"
                    onClick={(event) =>
                      scrollHandler(event, socialMediaMarketing)
                    }
                  >
                    Social Media Marketing
                  </Link>
                </li>
                <li>
                  <Link
                    to="#Organic-Reach"
                    onClick={(event) => scrollHandler(event, organicReach)}
                  >
                    Organic Reach
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
      <div ref={creativeDesign}>
        <CreativeDesignPage />
      </div>
      <div ref={paidAdvertising}>
        <PaidAdvertising />
      </div>
      <div ref={socialMediaMarketing}>
        <SocialMediaMarketing />
      </div>
      <div ref={organicReach}>
        <OrganicReach />
      </div>
    </>
  );
};

export default ServicePage;
