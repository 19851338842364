import React, { useState } from "react";
import "../Style/contactForm.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import Form from "./Form";

const ContactForm = () => {
  return (
    <>
      <div class="contact_us_green">
        <div class="responsive-container-block big-container">
          <div class="responsive-container-block container">
            <div
              class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-7 wk-ipadp-10 line"
              id="i69b-2"
            >
              {/* ---------------- */}

              <Form />

              {/* ------------------------ */}
            </div>
            <div
              class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-5 wk-ipadp-10"
              id="ifgi"
            >
              <div class="container-box">
                <div class="text-content">
                  <p
                    class="text-blk contactus-head"
                    style={{ color: "#1b3244" }}
                  >
                    Contact Us
                  </p>
                  <p class="text-blk contactus-subhead">
                    Let's embark on this exciting journey together and bring
                    your digital dreams to life.
                  </p>
                </div>
                <div class="workik-contact-bigbox">
                  <div class="workik-contact-box">
                    <div
                      class="phone text-box"
                      onClick={() => (window.location = "tel:+917589065458")}
                    >
                      <FontAwesomeIcon icon={faPhone} class="contact-svg" />
                      <p class="contact-text">+91 7589065458</p>
                    </div>
                    <div
                      class="address text-box"
                      onClick={() =>
                        (window.location = "mailto:help@craqsolutions.com")
                      }
                    >
                      <FontAwesomeIcon icon={faEnvelope} class="contact-svg" />
                      <p class="contact-text">help@craqsolutions.com</p>
                    </div>
                    <div class="mail text-box">
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        class="contact-svg"
                      />

                      <p class="contact-text">
                        Sector 102 Gurugram, Haryana, 122003
                      </p>
                    </div>
                  </div>
                  <div class="social-media-links">
                    <a href="/">
                      <img
                        class="social-svg"
                        id="is9ym"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-mail.svg"
                      />
                    </a>
                    <a href="/">
                      <img
                        class="social-svg"
                        id="i706n"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-twitter.svg"
                      />
                    </a>
                    <a href="/">
                      <img
                        class="social-svg"
                        id="ib9ve"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-insta.svg"
                      />
                    </a>
                    <a href="/">
                      <img
                        class="social-svg"
                        id="ie9fx"
                        src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/gray-fb.svg"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
