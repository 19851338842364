import { useEffect } from "react";
import AboutUs from "./components/AboutUsPage/components/AboutUs";
import Navbar from "./components/Navbar/components/Navbar";
import CompanyAboutLandingPage from "./components/LandingPage/components/CompanyAboutLandingPage";
import Contact from "./components/ContactUsPage/components/Contact";
import Footer from "./components/Footer/components/Footer";
import FontPageLandingPage from "./components/LandingPage/components/FontPageLandingPage";
import ServicePage from "./components/ServicesPage/components/ServiceMainPage";
import ServicesSuiteLandingPage from "./components/LandingPage/components/ServicesSuiteLandingPage";
import "./App.css";
import { Router, Route, Link, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
function App() {
  const LandingPageCombine = () => {
    const routePath = useLocation();
    const onTop = () => {
      window.scrollTo(0, 0);
    };
    useEffect(() => {
      onTop();
    }, [routePath]);

    return (
      <>
        <FontPageLandingPage />
        <CompanyAboutLandingPage />
        <ServicesSuiteLandingPage />
      </>
    );
  };

  return (
    <>
      <Navbar />
      <Helmet>
        <title>Build Brand Presence|Maximise ROI|Craq Digital Solutions</title>
        <meta name="description" content="Uplift your business with our proven omni channel digital services that delivers results"/>
        <meta
          name="keywords"
          content="craq solutions, craq, digital marketing, craqsolutions.com, craq digital solutions"
        />
        <meta property="og:title" content="Build Brand Presence|Maximise ROI|Craq Digital Solutions"/>
        <meta property="og:site_name" content="Craq Digital Solutions"/>
        <meta property="og:url" content="https://www.craqsolutions.com"/>
        <meta property="og:type" content="business.business"/>
        <meta property="og:description" content="Uplift your business with our proven omni channel digital services that delivers results"
        />
        <meta property="og:image" content="https://www.craqsolutions.com/favicon.png"/>
   
      </Helmet>
      <Routes>
        <Route path="/" element={<LandingPageCombine />} />
        <Route path="/Services" element={<ServicePage />} />
        <Route path="/About-Us" element={<AboutUs />} />
        <Route path="/Contact-Us" element={<Contact />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

// https://www.geeksforgeeks.org/how-to-migrate-from-create-react-app-to-next-js/
