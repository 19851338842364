import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../Style/Style_ServicesSuiteLandingPage.scss";

const ServicesLandingPage = () => {
  const [activeButton, setActiveButton] = useState({
    creative: "#2596be",
    paid: "#7495a5",
    social: "#7495a5",
    organic: "#7495a5",
  });

  const [content, setContent] = useState({
    name: "Creative Services",
    about:
      " Our Team consists of the best tech experts who can turn ideas into technology. Their expertise in flawless App development, modern website designing, creative landing pages, stunning Logo Creations, Animation designing, and custom graphics adds value to the client’s requirements. Their Experience and Technology knowledge deliver the best solutions, which can be easily accessed and achieved.",
    list: [
      "Website Designs",
      "E-commerce Website",
      "Mobile Application",
      "Landing Pages",
      "Graphic designs",
      "UI/UX Designs",
    ],
  });

  const CreativeFunc = () => {
    setActiveButton({
      ...activeButton,
      creative: "#2596be",
      paid: "#7495a5",
      social: "#7495a5",
      organic: "#7495a5",
    });

    setContent({
      ...content,
      name: "Creative Services",
      about:
        " Our Team consists of the best tech experts who can turn ideas into technology. Their expertise in flawless App development, modern website designing, creative landing pages, stunning Logo Creations, Animation designing, and custom graphics adds value to the client’s requirements. Their Experience and Technology knowledge deliver the best solutions, which can be easily accessed and achieved.",
      list: [
        "Website Designs",
        "E-commerce Website",
        "Mobile Application",
        "Landing Pages",
        "Graphic designs",
        "UI/UX Designs",
      ],
    });
  };
  const paidAdvertisingFunc = () => {
    setActiveButton({
      ...activeButton,
      creative: "#7495a5",
      paid: "#2596be",
      social: "#7495a5",
      organic: "#7495a5",
    });
    setContent({
      ...content,
      name: "Paid Advertising",
      about:
        "Digital Marketing is the forte of Craq. We understand the Customer’s business, and its challenges and then decide the best suitable marketing techniques and online platforms which add fruitful results. We create effective ad campaigns, that attract and bring the attention of the target audience, to help businesses begin their journey on digital platforms and get more quality leads. Our Experts put dedicated efforts into real live data, performance algorithms, KPIs, and data analysis to boost the marketing campaigns.",
      list: [
        "Google Ads",
        "E-commerce Ads",
        "Bing Ads",
        "Outbrains Ads",
        "Yahoo Ads",
        "Taboola Ads",
      ],
    });
  };
  const socialMediaFunc = () => {
    setActiveButton({
      ...activeButton,
      creative: "#7495a5",
      paid: "#7495a5",
      social: "#2596be",
      organic: "#7495a5",
    });
    setContent({
      ...content,
      name: "Social Media Marketing",
      about:
        "In today’s world, social media is becoming a part of everyone’s daily routine. If your brand is not on social media then you are missing your chance to reach your potential intended audience. In Craq, the Social media team is a fusion of young brains and Experience players. You will love the way our team builds your presence like a premium brand on social media platforms i.e., Linked In, Instagram, Meta (Facebook), X (Twitter), YouTube, Snapchat, Tiktok. Let’s start your social media brand awareness journey with us.",
      list: [
        "Linked In",
        "Instagram",
        "Meta (Facebook)",
        "YouTube",
        "Twitter",
        "Snapchat",
      ],
    });
  };
  const organicFunc = () => {
    setActiveButton({
      ...activeButton,
      creative: "#7495a5",
      paid: "#7495a5",
      social: "#7495a5",
      organic: "#2596be",
    });
    setContent({
      ...content,
      name: "Organic Reach",
      about:
        "We work on real and natural content by which the audience can attract and relate to their requirements. We help websites to increase their reach organically and come to the top of search engines. Our Analyst does the deep-down analysis, brings the website analysis reports, and suggests the changes according to the market trend.",
      list: [
        "Search Engine  Optimization",
        "Content Writing",
        "Local Search Optimization",
        "Website Analysis",
        "Campaign Audits",
        "Email Marketing",
      ],
    });
  };

  return (
    <>
      <div className="servicesOutteBody">
        <div className="servicesInnerBody">
          <div className="serviceTitle">
            <h1>Our Services Suite</h1>
            <ul>
              <li
                onClick={CreativeFunc}
                style={{ color: activeButton.creative }}
              >
                Creative Services
              </li>
              <li
                onClick={paidAdvertisingFunc}
                style={{ color: activeButton.paid }}
              >
                Paid Advertising
              </li>
              <li
                onClick={socialMediaFunc}
                style={{ color: activeButton.social }}
              >
                Social Media Marketing
              </li>
              <li onClick={organicFunc} style={{ color: activeButton.organic }}>
                Organic Reach
              </li>
            </ul>
          </div>
          <div className="serviceItemBody">
            <div className="serviceItemImgBody">
              {/* <img src={servicesSuiteImg} /> */}
              <div className="servicesSuiteImg"></div>
            </div>
            <div className="serviceItemTextBody">
              <h1>{content.name}</h1>
              <p>{content.about}</p>
              <span>
                {content.list.map((data, index) => {
                  return (
                    <p key={index}>
                      <FontAwesomeIcon
                        icon={faSquareCheck}
                        style={{ marginRight: "10px", color: "#1b3244" }}
                      />
                      {data}
                    </p>
                  );
                })}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesLandingPage;
