import React, { useState } from "react";
import { useForm } from "@formspree/react";
import "../Style/form.scss";

const Form = () => {
  const [state, handleSubmit] = useForm("xdorvrjj");
  if (state.succeeded) {
    return (
      <>
        <div className="submittedText">
          <p>We'll be in touch shortly</p>
        </div>
      </>
    );
  }
  return (
    <form class="form-box" onSubmit={handleSubmit}>
      <div class="container-block form-wrapper">
        <div class="head-text-box">
          <p class="text-blk contactus-head" style={{ color: "#1b3244" }}>
            Contact Us
          </p>
          <p class="text-blk contactus-subhead">
            Let's embark on this exciting journey together and bring your
            digital dreams to life.
          </p>
        </div>
        <div class="responsive-container-block">
          <div
            class="responsive-cell-block wk-ipadp-6 wk-tab-12 wk-mobile-12 wk-desk-6"
            id="i10mt-6"
          >
            <p class="text-blk input-title">FIRST NAME</p>
            <input
              class="input"
              id="ijowk-6"
              name="first_name"
              type="text"
              // value={formData.first_name}
              // onChange={handleInputChange}
              required
            />
          </div>
          <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
            <p class="text-blk input-title">LAST NAME</p>
            <input
              class="input"
              id="indfi-4"
              name="last_name"
              type="text"
              // value={formData.last_name}
              // onChange={handleInputChange}
              required
            />
          </div>
          <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
            <p class="text-blk input-title">EMAIL</p>
            <input
              class="input"
              id="ipmgh-6"
              name="email"
              type="email"
              // value={formData.email}
              // onChange={handleInputChange}
              required
            />
          </div>
          <div class="responsive-cell-block wk-desk-6 wk-ipadp-6 wk-tab-12 wk-mobile-12">
            <p class="text-blk input-title">PHONE NUMBER</p>
            <input
              class="input"
              id="imgis-5"
              name="phone_number"
              type="number"
              // value={formData.phone_number}
              // onChange={handleInputChange}
              required
            />
          </div>
          <div
            class="responsive-cell-block wk-tab-12 wk-mobile-12 wk-desk-12 wk-ipadp-12"
            id="i634i-6"
          >
            <p class="text-blk input-title">WHAT DO YOU HAVE IN MIND</p>
            <textarea
              class="textinput"
              id="i5vyy-6"
              placeholder="Please enter query..."
              name="message"
              // value={formData.message}
              // onChange={handleInputChange}
              required
            ></textarea>
          </div>
        </div>
        <div class="btn-wrapper">
          <button class="submit-btn" type="submit" disabled={state.submitting}>
            Submit
          </button>
        </div>
      </div>
    </form>
  );
};

export default Form;
