import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faLocationDot,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import GoogleIcon from "@mui/icons-material/Google";
import CopyrightIcon from "@mui/icons-material/Copyright";
import logo from "../Img/footerLogo.png";
import "../Style/footer.scss";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="footerOutterBody">
        <div className="footerInnerBody">
          <div className="aboveFooterOutterBody">
            <div className="aboveFooterInnerBody">
              <ul>
                <li>
                  <a href="tel:+917589065458">
                    <FontAwesomeIcon icon={faPhone} id="innerFooter" />
                    <span>
                      <p>Call Us</p>
                      <h2>+91 7589065458</h2>
                    </span>
                  </a>
                </li>
                <li>
                  <div className="line"></div>
                </li>
                <li>
                  <a href="mailto:help@craqsolutions.com">
                    <FontAwesomeIcon icon={faEnvelope} id="innerFooter" />
                    <span>
                      <p>Mail Us</p>
                      <h2>help@craqsolutions.com</h2>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mainFooter">
            <div className="aboutCompany">
              <img src={logo} alt="logo" />
              <p>
                We specialize in delivering cutting-edge digital marketing
                solutions that drive success and growth for your business.
              </p>
            </div>
            <div className="addressAndInfo">
              <h2>India</h2>
              <ul>
                <li>
                  <FontAwesomeIcon icon={faLocationDot} id="footerIcon" />
                  <p>Sector 102 Gurugram, Haryana, 122003 </p>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} id="footerIcon" />
                  <p>
                    <a href="tel:+917589065458">+91 7589065458</a>
                  </p>
                </li>
                <li>
                  <FontAwesomeIcon icon={faEnvelope} id="footerIcon" />
                  <p>
                    <a href="mailto:help@craqsolutions.com">Mail Us</a>
                  </p>
                </li>
              </ul>
            </div>
            <div className="quickLinks">
              <h2>Quick Links</h2>
              <ul>
                <li>
                  <Link to="/" id="footerQuickLink">
                    Home
                  </Link>
                </li>
                <li>
                  <Link to="/Services" id="footerQuickLink">
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="/About-Us" id="footerQuickLink">
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/Contact-Us" id="footerQuickLink">
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>
            <div className="services">
              <h2>Services</h2>
              <ul>
                <li>
                  <Link to="/Services" id="footerQuickLink">
                    Creative Design
                  </Link>
                </li>
                <li>
                  <Link to="/Services" id="footerQuickLink">
                    Paid Advertising
                  </Link>
                </li>
                <li>
                  <Link to="/Services" id="footerQuickLink">
                    Social Media Marketing
                  </Link>
                </li>
                <li>
                  <Link to="/Services" id="footerQuickLink">
                    Organic Reach
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="copyRightandFollowBody">
            <div className="followUsBody">
              <ul>
                <li>
                  <FacebookIcon id="mediaIcon" />
                </li>
                <li>
                  <InstagramIcon id="mediaIcon" />
                </li>
              </ul>
            </div>
            <div className="copyrightBody">
              <ul>
                <li>
                  <TwitterIcon id="mediaIcon" />
                </li>
                <li>
                  <GoogleIcon id="mediaIcon" />
                </li>
              </ul>
              <h1>
                <CopyrightIcon style={{ fontSize: "11px" }} />
                &nbsp;All Rights Reserved Craq Digital Solutions
              </h1>
            </div>
          </div>
          <div className="responsiveCopyRight">
            <h1>
              <CopyrightIcon style={{ fontSize: "11px" }} />
              &nbsp;All Rights Reserved Craq Digital Solutions
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
