import React, { useEffect } from "react";
import "../Style/contact.scss";

import ContactForm from "./ContactForm";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";

const Contact = () => {
  // -----------------------

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  // ---------------------
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="contact us" />
        <meta
          name="keywords"
          content="craq solutions, craq, digital marketing, craqsolutions.com, contact us, craq digital solutions"
        />
      </Helmet>
      <div className="contactPageOutterBody">
        <div className="contactPageInnerBody">
          <div className="contactPageBannerAndText">
            <div className="contactPageBannerText">
              <span>
                <h1>Contact Us</h1>
                <p>Welcome to Craq Digital Solutions</p>
              </span>
            </div>
          </div>
          <div className="contactPageForm">
            <h1>Get In Touch</h1>
            <ContactForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
