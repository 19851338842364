import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import img from "../Img/logo.gif";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import "../style/navbar.scss";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";

function NavbarFunc() {
  const [isNavbarFixed, setIsNavbarFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // If the user scrolls down more than 100 pixels, set the navbar to be fixed
      setIsNavbarFixed(window.scrollY > 35);
    };

    // Attach the event listener for scrolling
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component is unmounted
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="upperNavbar">
        <div className="upperNavbarLeftSide">
          <Link to="/Contact-Us">
            <button>Get Started</button>
          </Link>
          <p>Yours Digital Journey Companions</p>
        </div>
        <div className="upperNavbarRightSide">
          <p>
            <a href="tel:+917589065458">
              <FontAwesomeIcon
                icon={faPhone}
                style={{ marginRight: "5px", color: "#1b3244" }}
              />
              +91 7589065458
            </a>
          </p>
          <p>
            <a href="mailto:help@craqsolutions.com">
              <FontAwesomeIcon
                icon={faEnvelope}
                style={{ marginRight: "5px", color: "#1b3244" }}
              />
              {/* craqsolutions@gmail.com */}
              help@craqsolutions.com
            </a>
          </p>
        </div>
      </div>
      <hr id="hrStyle" />
      <div className={isNavbarFixed ? "navbar fixed" : "navbar"}>
        <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
          <Container>
            <LinkContainer to="/">
              <Navbar.Brand>
                <img src={img} style={{ width: "120px" }} />
              </Navbar.Brand>
            </LinkContainer>
            <div style={{ display: "flex", alignItems: "center" }}>
              <FontAwesomeIcon
                icon={faPhone}
                onClick={() => (window.location = "tel:+917589065458")}
                style={{ marginRight: "30px", fontSize: "30px" }}
                id="responvieCallBtn"
              />
              <Navbar.Toggle aria-controls="responsive-navbar-nav" id="hh" />
            </div>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                <LinkContainer to="/">
                  <Nav.Link id="NavlinkStyle">Home</Nav.Link>
                </LinkContainer>
                <LinkContainer to="Services">
                  <Nav.Link id="NavlinkStyle">Services</Nav.Link>
                </LinkContainer>
                <LinkContainer to="About-Us">
                  <Nav.Link id="NavlinkStyle">About Us</Nav.Link>
                </LinkContainer>
                <LinkContainer to="Contact-Us">
                  <Nav.Link id="NavlinkStyle">Contact Us</Nav.Link>
                </LinkContainer>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
      <hr style={{ margin: 0 }} />
    </>
  );
}

export default NavbarFunc;
