import React from "react";
import "../Style/paidAdvertising.scss";
import marketing from "../Img/paid.avif";
// -------
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// --
import GoogleIcon from "@mui/icons-material/Google";
import VideocamIcon from "@mui/icons-material/Videocam";
import { faYahoo } from "@fortawesome/free-brands-svg-icons";
import { faBullhorn, faRectangleAd } from "@fortawesome/free-solid-svg-icons";

function PaidAdvertising() {
  return (
    <>
      <div className="advertisingPageOutterBody">
        <div className="advertisingPageInnerBody">
          <div className="advertisingPageTitleAndImg">
            <div className="advertisingPageTitle">
              <h1>Paid Advertising</h1>
              <p>
                Digital Marketing is the forte of Craq. We understand the
                Customer’s business, and its challenges and then decide the best
                suitable marketing techniques and online platforms which add
                fruitful results. We create effective ad campaigns, that attract
                and bring the attention of the target audience, to help
                businesses begin their journey on digital platforms and get more
                quality leads. Our Experts put dedicated efforts into real live
                data, performance algorithms, KPIs, and data analysis to boost
                the marketing campaigns.
              </p>
            </div>
            <img src={marketing} />
          </div>
          <div className="advertisingPageItem">
            <ul>
              <li>
                <div className="advertisingPageItemText">
                  <GoogleIcon style={{ fontSize: "40px" }} />
                  <h1>Google Ads</h1>
                  <p>
                    Our Expert's experience in Google ads is the biggest
                    strength of Craq. We create and manage Google ads
                    constructively to drive the best results. Trust us, it adds
                    value to your business.
                  </p>
                </div>
              </li>
              <li>
                <div className="advertisingPageItemText">
                  <FontAwesomeIcon
                    icon={faRectangleAd}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>E-commerce Ads</h1>
                  <p>
                    Ad campaigns on E-commerce platforms like Amazon, Flipkart,
                    and Walmart expand your brand’s reach and increase sales.
                  </p>
                </div>
              </li>
              <li>
                <div className="advertisingPageItemText">
                  <FontAwesomeIcon
                    icon={faRectangleAd}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Bing Ads</h1>
                  <p>
                    Let professionals handle and manage your ad campaigns using
                    the best strategies to drive the desired result for your
                    business.
                  </p>
                </div>
              </li>
              <li>
                <div className="advertisingPageItemText">
                  <VideocamIcon style={{ fontSize: "40px" }} />
                  <h1>Outbrains Ads</h1>
                  <p>
                    Build your brand’s image with us. Promote your brand,
                    products, and services through Outbrains Ads to reach your
                    target audience.
                  </p>
                </div>
              </li>
              <li>
                <div className="advertisingPageItemText">
                  <FontAwesomeIcon
                    icon={faYahoo}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Yahoo Ads</h1>
                  <p>
                    Create your brand awareness over Yahoo ads. Our experts are
                    proficient in the backend algorithms that boost your online
                    presence.
                  </p>
                </div>
              </li>
              <li>
                <div className="advertisingPageItemText">
                  <FontAwesomeIcon
                    icon={faRectangleAd}
                    style={{ fontSize: "40px" }}
                  />
                  <h1>Taboola Ads</h1>
                  <p>
                    Grab your goals with Taboola ads through our tailored
                    advertising techniques and take your brand one step ahead.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaidAdvertising;
