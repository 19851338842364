import React from "react";
import "../Style/creativeDesign.scss";
import creativePageImg from "../Img/creativePageImg.png";
import DrawIcon from "@mui/icons-material/Draw";
import FormatTextdirectionRToLIcon from "@mui/icons-material/FormatTextdirectionRToL";
import WidgetsIcon from "@mui/icons-material/Widgets";
import DevicesIcon from "@mui/icons-material/Devices";
import Shop2Icon from "@mui/icons-material/Shop2";
import MobileScreenShareIcon from "@mui/icons-material/MobileScreenShare";
const CreativeDesignPage = () => {
  return (
    <>
      <div className="creativePageOutterBody">
        <div className="creativePageInnerBody" id="navbarStyleEditReason">
          <div className="creativePageTitleAndImg">
            <img src={creativePageImg} />
            <div className="creativePageTitle">
              <h1>Creative Services</h1>
              <p>
                Our Team consists of the best tech experts who can turn ideas
                into technology. Their expertise in flawless App development,
                modern website designing, creative landing pages, stunning Logo
                Creations, Animation designing, and custom graphics adds value
                to the client’s requirements. Their Experience and Technology
                knowledge deliver the best solutions, which can be easily
                accessed and achieved.
              </p>
            </div>
          </div>
          <div className="creativePageItem">
            <ul>
              <li>
                <div className="creativePageItemText">
                  <DrawIcon style={{ fontSize: "40px" }} />
                  <h1>Website Designs</h1>
                  <p>
                    We create Iconic and stunning website that respond quickly
                    and flawlessly and helps you grow like a brand.
                  </p>
                </div>
              </li>
              <li>
                <div className="creativePageItemText">
                  <Shop2Icon style={{ fontSize: "40px" }} />
                  <h1>E-commerce Website</h1>
                  <p>
                    Get your E-commerce website like a big brand, which can
                    handle huge online traffic, integrate payment gateways,
                    responsive to all screens.
                  </p>
                </div>
              </li>
              <li>
                <div className="creativePageItemText">
                  <MobileScreenShareIcon style={{ fontSize: "40px" }} />
                  <h1>Mobile Application</h1>
                  <p>
                    We deliver eye-catching Mobile applications for your
                    business as per requirements. We are experts in both Android
                    and IOS platforms.
                  </p>
                </div>
              </li>
              <li>
                <div className="creativePageItemText">
                  <DevicesIcon style={{ fontSize: "40px" }} />
                  <h1>Landing Pages</h1>
                  <p>
                    We create creative landing pages with all the Information
                    about your business that drives results for your leads.
                  </p>
                </div>
              </li>
              <li>
                <div className="creativePageItemText">
                  <FormatTextdirectionRToLIcon style={{ fontSize: "40px" }} />
                  <h1>Graphic Designs</h1>
                  <p>
                    Our designers create attractive designs and logos, which
                    create an impact on a target audience and help your business
                    to be more familiar.
                  </p>
                </div>
              </li>
              <li>
                <div className="creativePageItemText">
                  <WidgetsIcon style={{ fontSize: "40px" }} />
                  <h1>UI/UX Designs</h1>
                  <p>
                    Our team creates user interfaces which brings more smoother
                    process for website and application development.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreativeDesignPage;
